import React from "react"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import { Helmet } from "react-helmet"

export default function ContactUs() {
   return (
      <Layout>
         <Helmet>
				<meta charSet="utf-8" />
				<title>JustPax Collective | Contact Us</title>
				<link rel="canonical" href="http://justpaxcollective.com" />
			</Helmet>
         <section className="contact-us">
            <section className="wrapper contact-us-form-container">
               <section class="wrapper">
                  <h1 style={{marginBottom:`3rem`}}>Contact Us</h1>
                  <ContactForm>
                     <div id="content"></div>
                  </ContactForm>
               </section>
               
            </section>
         </section>

      </Layout>
   )
}