import React from 'react'
import HubspotForm from 'react-hubspot-form'

export default function Form() {
   return (
      <HubspotForm
         portalId='8440845'
         formId='4b3e9bec-1c62-467e-beb7-34cc645e254c'
         onSubmit={() => console.log('Submit!')}
         onReady={(form) => console.log('Form ready!')}
         loading={<div>Loading...</div>}
      />
   )
}
